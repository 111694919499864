import React, { useEffect } from "react";
import Button from "../components/global/Button";
import { useHistory, useLocation } from "react-router-dom";
import { JourneyType, JOURNEY_COLOURS } from "../shared/Theme";
import Input from "../components/global/Input";
import ProgressBar from "../components/global/ProgressBar";
import { ReactComponent as Chevron } from "../svg/chevron.svg";

import {
  addDiscountCode,
  getAddressDetail,
  lookupAddress,
  lookupRestOfWorldAddress,
  updateDetails,
  updatePlan,
  addSkyVcn
} from "../shared/Client";
import Loader from "../components/global/Loader";
import DropText from "../components/global/DropText";
import "../styles/join/details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import countries from "../data/countries.json";
import Flag from "../components/global/Flag";
import { CountryCode } from "../shared/Models";
import CheckBox from "../components/global/CheckBox";
import { DiscountPlan, Plan } from "../shared/Models";
import SearchAddress from "../shared/SearchAddress";
import { parseSearch } from "../shared/helpers";
import { trackUserOption } from "../utils/tracking";

export type YourDetailsProps = {
  journey: JourneyType;
};

type SelectedAddress = {
  addressline1: string;
  addressline2: string;
  addressline3?: string;
  summaryline: string;
  subbuildingname?: string;
  number: string;
  premise?: string;
  street: string;
  posttown: string;
  county: string;
  postcode: string;
};

export type Country = {
  id: number;
  name: string;
  code: string;
};

export type Customer = {
  customer: CustomerDetails;
};

export type Address = {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  postcode: string;
  country_code: string;
};

export type SkyQ = "0" | "1";

export type CustomerDetails = {
  given_name: string;
  family_name: string;
  sky_q: SkyQ;
  title?: string;
  vcn_number?: string;
  phone: string;
  addresses_attributes: Address[];
};

const YourDetails: React.FC<YourDetailsProps> = ({ journey }) => {
  const history = useHistory();
  const location: any = useLocation();
  // const [firstName, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  const lastAddress1 = sessionStorage.getItem("address1");
  const lastAddress2 = sessionStorage.getItem("address2");
  const lastAddress3 = sessionStorage.getItem("address3");
  const lastCity = sessionStorage.getItem("city");
  const lastPostcode = sessionStorage.getItem("postcode");
  const lastManualAddress = sessionStorage.getItem("manualAddress") == "true" ? true : false;
  const lastSkyQChecked = sessionStorage.getItem("skyQChecked");
  const lastPhoneNumber = sessionStorage.getItem("phoneNumber");
  const lastCountryCode = sessionStorage.getItem("countryCode");
  const lastSelectedAddress = sessionStorage
    ? sessionStorage.getItem("selectedAddress")
    : undefined;
  const lastSkyNumber = sessionStorage.getItem("skyNumber");
  const lastSelectedPlan = sessionStorage && sessionStorage.getItem("planSelected");
  const paymentMethod = localStorage && localStorage.getItem("noPaymentMethod");
  const restOfWorld = sessionStorage.getItem("restOfWorld") == "true" ? true : false;

  const [pattern, setPattern] = React.useState("");
  const [manualAddress, setManualAddress] = React.useState(
    lastManualAddress ? lastManualAddress : false
  );
  const [address1, setAddress1] = React.useState(lastAddress1 ? lastAddress1 : "");
  const [address2, setAddress2] = React.useState(lastAddress2 ? lastAddress2 : "");
  const [address3, setAddress3] = React.useState(lastAddress3 ? lastAddress3 : "");
  const [city, setCity] = React.useState(lastCity ? lastCity : "");
  const [postcode, setPostcode] = React.useState(lastPostcode ? lastPostcode : "");
  const [planSelected, setPlanSelected] = React.useState<Plan | DiscountPlan | undefined>(
    lastSelectedPlan ? JSON.parse(lastSelectedPlan) : undefined
  );
  const [isFreeDayPass, setIsFreeDayPass] = React.useState(
    planSelected && planSelected.name === "Free Day Pass" ? true : false
  );
  const [noPaymentMethod, setNoPaymentMethod] = React.useState<boolean | undefined>(
    paymentMethod == "true" ? true : false
  );
  const [isFreeTrial, setIsFreeTrial] = React.useState<boolean | undefined>(
    planSelected?.name.toLocaleLowerCase().includes("free") ||
      planSelected?.name.toLocaleLowerCase().includes("trial")
  );

  const [phoneNumber, setPhoneNumber] = React.useState(lastPhoneNumber ? lastPhoneNumber : "+44");
  const [searchingRestOfWorld, setSearchingRestOfWorld] = React.useState(
    restOfWorld ? restOfWorld : false
  );
  const [countryCode, setCountryCode] = React.useState<CountryCode>(
    lastCountryCode ? (lastCountryCode as CountryCode) : "UK"
  );

  const [selectedAddress, setSelectedAddress] = React.useState<SelectedAddress | undefined>(
    lastSelectedAddress != "undefined" && lastSelectedAddress != null && lastSelectedAddress != ""
      ? JSON.parse(lastSelectedAddress ? lastSelectedAddress : "")
      : undefined
  );
  const [skyNumber, setSkyNumber] = React.useState<string>(lastSkyNumber ? lastSkyNumber : "");
  const [skyNumberWrongLength, setSkyNumberWrongLength] = React.useState(false);
  const [discountCode, setDiscountCode] = React.useState("");
  const [validForm, setValidForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const SKY_Q_CHECKBOX_ID = "skyQCheckbox";
  const planSupportsSkyQ = sessionStorage.getItem("plan_supports_sky_q") === "true";
  const [skyQChecked, setSkyQChecked] = React.useState<boolean>(
    !!lastSkyQChecked ? lastSkyQChecked === "true" : false
  );
  const [country, setCountry] = React.useState(lastCountryCode ? lastCountryCode : "UK");
  const token =
    sessionStorage.getItem("memberToken") || new URLSearchParams(location.search).get("token");

  const mapCountry = (countryCode: CountryCode): string => {
    if (!searchingRestOfWorld) {
      const selectedNonRowCountry = countries.find((c) => c.iso2 === countryCode)?.iso3;
      if (!selectedNonRowCountry)
        throw new Error("Selected country code not found in countries.json");

      return selectedNonRowCountry;
    }
    return "GBR"; // Return ROW in all other cases
  };

  const goBackClicked = () => {
    return history.push(`/join/choose-package?token=${token}`);
  };

  const YourDetailsAndProceed = async () => {
    setLoading(true);
    // post to server to YourDetails code
    // errors present?
    //     yes: setErrorState
    //    no: go to success to YourDetails
    // set any data in the parent frame.

    if ((!address1 && !city && !postcode) || !token) {
      return setError("Sorry, something went wrong. Please try again.");
    }

    if (address1.length > 50) return setError("Address Line1 exceeds the maximum limit of 50.");
    else if (address2?.length > 50)
      return setError("Address Line2 exceeds the maximum limit of 50.");
    else if (address3?.length > 50)
      return setError("Address Line3 exceeds the maximum limit of 50.");
    const sky_q_checkbox = (document.getElementById(SKY_Q_CHECKBOX_ID) as HTMLInputElement)?.checked
      ? "1"
      : ("0" as SkyQ);

    const vcn_number = skyNumber.replace(" ", "").replace(/-/g, "");
    const customer = {
      customer: {
        phone: phoneNumber,
        address_attributes: {
          address1: address1,
          address2: address2,
          address3: address3,
          city: city,
          postcode: postcode,
          country_code: mapCountry(countryCode) // we wanna post iso3, not iso2 used by the address finder api
        }
      }
    };

    // check for plan and update it if found.
    const plan = parseSearch(history.location.search, "plan");
    if (Object.keys(plan || {})?.length && token?.length) {
      await updatePlan(
        {
          cart: {
            id: plan.id.toString(),
            plan_type: plan.plan_type
          }
        },
        token
      );
    }

    if (vcn_number) {
      addSkyVcn(vcn_number, sky_q_checkbox)
        .then(async (res) => {
          await updateMemberDetails(token, customer, sky_q_checkbox);
        })
        .catch((err: any) => {
          setLoading(false);
          setError(err.message);
          return false;
        });
    } else {
      await updateMemberDetails(token, customer, sky_q_checkbox);
    }
  };

  const updateMemberDetails = async (token: string, customer: any, sky_q_checkbox: string) => {
    updateDetails(token, customer)
      .then((res) => {
        sessionStorage.setItem("skyQChecked", sky_q_checkbox == "1" ? "true" : "false");
        sessionStorage.setItem("phoneNumber", phoneNumber);
        sessionStorage.setItem("address1", address1);
        sessionStorage.setItem("address2", address2);
        sessionStorage.setItem("address3", address3);
        sessionStorage.setItem("city", city);
        sessionStorage.setItem("postcode", postcode);
        sessionStorage.setItem("countryCode", countryCode);
        sessionStorage.setItem("manualAddress", manualAddress ? "true" : "false");
        sessionStorage.setItem("selectedAddress", JSON.stringify(selectedAddress));
        sessionStorage.setItem("skyNumber", skyNumber);
        sessionStorage.setItem("restOfWorld", searchingRestOfWorld ? "true" : "false");
        sessionStorage.setItem("payment_error", "");

        trackUserOption({
          event: "your_details_completed",
          join_option_selected: { plan_name: planSelected?.name || "unknown" }
        });

        setLoading(false);

        if (noPaymentMethod && planSelected?.name == "Free Day Pass") {
          localStorage.setItem("noPaymentMethod", "false");
          return history.push(`/join/success`);
        } else return history.push(`/join/payment`);
      })
      .catch((err: any) => {
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    const validPhone = !!phoneNumber.match(/\+?[0-9]{5,15}/);
    let validFreeDayPass = isFreeDayPass ? (noPaymentMethod ? true : false) : true;
    validFreeDayPass = true;
    address1 && city && postcode && validPhone && !skyNumberWrongLength && validFreeDayPass
      ? setValidForm(true)
      : setValidForm(false);
  }, [manualAddress, address1, city, postcode, phoneNumber, skyNumberWrongLength, noPaymentMethod]);

  // keeps button disabled until while entered sky code is either omitted or correct length
  useEffect(() => {
    const strippedSkyNumber = skyNumber.replace(/\s/g, "");

    if (skyNumber.length === 0) {
      return setSkyNumberWrongLength(false);
    }

    if (strippedSkyNumber.length === 11) {
      return setSkyNumberWrongLength(false);
    }

    setSkyNumberWrongLength(true);
  }, [skyNumber]);

  const handleSkyQCheck = (e: any) => {
    setSkyQChecked(!!e.target.checked);
  };

  return (
    <>
      <span className="back white-link" onClick={() => goBackClicked()}>
        <Chevron className="left-chevron" /> Back
      </span>
      <ProgressBar activeDots={3} numberOfDots={4} />
      <h1 className={JOURNEY_COLOURS[journey] + " m-0 name-text"}>YOUR DETAILS</h1>
      <SearchAddress
        pattern={pattern}
        setPattern={setPattern}
        manualAddress={manualAddress}
        setManualAddress={setManualAddress}
        address1={address1}
        setAddress1={setAddress1}
        address2={address2}
        setAddress2={setAddress2}
        address3={address3}
        setAddress3={setAddress3}
        city={city}
        setCity={setCity}
        postcode={postcode}
        setPostcode={setPostcode}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
        setSearchingRestOfWorld={setSearchingRestOfWorld}
        country={country}
        setCountry={setCountry}
      />
      {country != "ROTW" && (
        <DropText
          open={!!lastSkyNumber}
          text="Activate on Sky"
          label="Your 9 digit Sky Viewing Card Number"
          minLength={9}
          maxLength={11}
          value={skyNumber}
          placeHolder="xxx-xxx-xxx"
          setValue={setSkyNumber}
          showSkyQCheckbox={planSupportsSkyQ}
          skyQCheckbox={
            <div
              className={`checkbox-container short sky-q-checkbox-container checked-${skyQChecked}`}>
              <CheckBox
                text={`Add Sky Q Mini access for an extra ${
                  countryCode == "IE" ? "€" : "£"
                }10 per month`}
                id={"skyQCheckbox"}
                handler={handleSkyQCheck}
                checked={skyQChecked}
                noMarginBottom={true}
              />
            </div>
          }
        />
      )}

      {!(planSelected?.plan_type == ("discount" as any)) && (
        <DropText
          discount={true}
          text="Promotional code"
          label={`${
            isFreeDayPass == true ? "Free Day Pass promotional code" : "Enter your promotional code"
          }`}
          value={discountCode}
          setValue={setDiscountCode}
          passFreeDayCode={isFreeDayPass ? isFreeDayPass : undefined}
          setNoPaymentMethod={setNoPaymentMethod}
        />
      )}

      {error ? <p className="error-text">{error}</p> : null}
      <div className="center">
        <Button
          type="submit"
          text="Continue"
          size="medium"
          disabled={!validForm}
          color={JOURNEY_COLOURS[journey]}
          rightComponent={loading ? <Loader color={JOURNEY_COLOURS[journey]} /> : null}
          onClick={() => YourDetailsAndProceed()}
        />
      </div>
    </>
  );
};

export default YourDetails;
