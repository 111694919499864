import React, { useEffect, useState } from "react";

import { JourneyType, JOURNEY_COLOURS } from "../shared/Theme";
import ProgressBar from "../components/global/ProgressBar";
import Card from "../components/global/Card";
import RadioGroup, { paymentSelectedObject } from "../components/global/RadioGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "../styles/global/payment.scss";
import "../styles/global/debit.scss";
import Input from "../components/global/Input";
import Button from "../components/global/Button";
import Loader from "../components/global/Loader";
import { RTV_API_KEY, RTV_BASE_URL } from "../shared/constants";
import {
  getCart,
  getPaymentStatus,
  postMessageToParent,
  sendDirectDebit,
  updatePlan
} from "../shared/Client";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Chevron } from "../svg/chevron.svg";
import { parseSearch } from "../shared/helpers";
import { trackUserOption } from "../utils/tracking";
import { ApiResponsePaymentStatus } from "../shared/Models";

export type PaymentProps = {
  journey: JourneyType;
};

export type DirectDebitDetails = {
  account_name: string;
  account_number: string;
  sort_code: string;
};

export type CartPlan = {
  type: string;
  name: string;
  cost: string;
  detail: string;
};

export type Cart = {
  plan_name: string;
  plans: CartPlan[];
  multibox_item_group: string;
  sky_qs: any; // TODO: define this
  discounts: any; // TODO: define this
  total: string;
  total_without_discount: string;
  currency_code: string;
  currency_symbol: string;
  package_description: string;
  duration_text: string;
};

const Payment: React.FC<PaymentProps> = ({ journey }) => {
  const history = useHistory();
  const location: any = useLocation();

  const [paymentSelected, setPaymentSelected] = useState<paymentSelectedObject>({
    creditCard: true,
    debitCard: false
  });
  const [debitAccountNumber, setDebitAccountNumber] = useState("");
  const [debitAccountName, setDebitAccountName] = useState("");
  const [debitAccountSortCode, setDebitAccountSortCode] = useState("");
  const [cart, setCart] = useState<Cart | null>(null);
  const [error, setError] = useState("");
  const [directDebitError, setDirectDebitError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [iframeSrcHtml, setIframeSrcHtml] = useState("");

  const [showPaymentOptions, setShowPaymentOptions] = useState(true);
  const [creditCardError, setCreditCardError] = useState(null);
  const [creditCardPaymentSuccess, setCreditCardPaymentSuccess] = useState(false);
  // const [loading, setLoading] = useState(true);

  // const countryCode = sessionStorage.getItem("countryCode");
  const showSEPA = cart?.currency_symbol != "£"; // show SEPA (IBAN/BIC) for non-UK countries (e.g. Ireland)

  // console.log("countryCode: ", countryCode);

  const ACCOUNT_NAME_MIN_LENGTH = 3;
  const ACCOUNT_NUMBER_MAX_LENGTH = showSEPA ? 34 : 10;
  const ACCOUNT_NUMBER_MIN_LENGTH = showSEPA ? 22 : 8;
  const SORT_CODE_MIN_LENGTH = showSEPA ? 8 : 8;
  const SORT_CODE_MAX_LENGTH = showSEPA ? 11 : 8;
  // const creditCardError = sessionStorage.getItem("payment_error");

  const handleMessages = (event: any) => {
    if (event.data === "payment_complete") {
      console.log("[LISTENER]::payment_complete");
      window.parent.postMessage("payment_complete", "*");
      sessionStorage.setItem("payment_error", "");
      trackUserOption({
        event: "payment_stage_completed",
        join_option_selected: {
          plan_name: cart?.plan_name || "unknown"
        }
      });
      window.location.href = "/join/success";
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessages);
    return () => {
      window.removeEventListener("message", handleMessages);
    };
  }, []);

  const updateState = (cart: ApiResponsePaymentStatus["cart"]) => {
    switch (cart.payment_status) {
      case null:
        if (!showPaymentOptions) setShowPaymentOptions(true);
        if (creditCardError) setCreditCardError(false);
        break;
      case "failed":
        console.log("[SWITCH]::payment_failed");
        setShowPaymentOptions(true);
        setCreditCardError("Payment failed. Please try again.");
        getSrc();
        break;
      case "completed":
        setShowPaymentOptions(false);
        setCreditCardError(null);
        setCreditCardPaymentSuccess(true);
        console.log("[SWITCH]::payment_complete");
        trackUserOption({
          event: "payment_stage_completed",
          join_option_selected: { plan_name: cart.plan_name || "unknown" }
        });
        window.location.href = "/join/success";
        break;
      case "in-progress":
        console.log("[SWITCH]::payment_in_progress");
        break;
      default:
        console.error(`Unknown payment status: ${cart.payment_status}`);
    }
  };

  useEffect(() => {
    const pollPaymentStatus = setInterval(async () => {
      const response = await getPaymentStatus();
      if (response.cart) updateState(response.cart);
    }, 4000);

    return () => clearInterval(pollPaymentStatus);
  }, []);

  const refreshCart = () => {
    getCart()
      .then((cart) => {
        setCart(cart);

        if (paymentSelected.debitCard) {
          sessionStorage.setItem("cart", JSON.stringify(cart));
        }
      })
      .catch((err) => setError(err));
  };

  useEffect(() => {
    const token =
      sessionStorage.getItem("memberToken") || new URLSearchParams(location.search).get("token");
    const plan = parseSearch(history.location.search, "plan");
    if (Object.keys(plan)?.length && token?.length) {
      updatePlan(
        {
          cart: {
            id: plan.id.toString(),
            plan_type: plan.plan_type
          }
        },
        token
      ).then(() => refreshCart());
    } else {
      refreshCart();
    }
  }, [paymentSelected]);

  const updateDebitAccountSortCode = (sc: string) => {
    if (showSEPA) {
      setDebitAccountSortCode(sc);
      return;
    } else {
      const formattedSortCode = sc.match(/[0-9]{1,2}/g)?.join("-") || sc;
      setDebitAccountSortCode(formattedSortCode);
    }
  };

  const submitDirectDebit = () => {
    setLoading(true);
    setDirectDebitError("");

    const data: DirectDebitDetails = {
      account_number: debitAccountNumber,
      account_name: debitAccountName,
      sort_code: debitAccountSortCode.replaceAll("-", "")
    };

    sendDirectDebit(data)
      .then((res) => {
        setLoading(false);
        if (res.active_contract) {
          console.log("[DIRECT DEBIT]::active_contract ", res);
          trackUserOption({
            event: "payment_stage_completed",
            join_option_selected: {
              plan_name: cart?.plan_name || "unknown"
            }
          });
          trackUserOption({
            event: "subscription_completed",
            join_option_selected: { plan_name: cart?.plan_name || "unknown" }
          });
          window.location.href = "/join/success";
        }
      })
      .catch((err) => {
        setLoading(false);
        setDirectDebitError(err.message);
      });
  };

  const goBackClicked = () => {
    return history.push(`/join/your-details?token=${sessionStorage.getItem("memberToken")}`);
  };

  // set paymentIframe to hidden when paymentSelected changes
  useEffect(() => {
    const iframe = document.getElementById("paymentIframe");
    if (iframe) iframe.hidden = paymentSelected.debitCard;
  }, [paymentSelected]);

  // we post to /api/v2/card which responds with a form with all hidden objects.
  // we need to 'automatically' submit that form, so we get a 302 whose body is the real form. It also sets a JSESSION cookie so beware.
  // we then need to set the iframe src to the 302's body.
  useEffect(() => {
    getSrc().catch((err) => {
      console.log("err: ", err);
    });
  }, []);

  async function getSrc() {
    const token = sessionStorage.getItem("memberToken");
    const headers: any = {
      "MEMBER-TOKEN": token,
      Authorization: token ? `Bearer ${token}` : "",
      "API-KEY": RTV_API_KEY
    };

    // if (BASIC_AUTH_TOKEN) headers.Authorization = `Basic ${BASIC_AUTH_TOKEN}`;

    const res: any = await fetch(`${RTV_BASE_URL}member/payment/cards/new`, {
      method: "GET",
      headers: headers
    });
    const blob = await res.blob();
    const text = await blob.text();

    setIframeSrcHtml(text);
  }

  // set formValid to true when debitAccountNumber changes
  useEffect(() => {
    const debitAccountNumberValid =
      debitAccountNumber.length >= ACCOUNT_NUMBER_MIN_LENGTH &&
      debitAccountNumber.length <= ACCOUNT_NUMBER_MAX_LENGTH;
    const debitAccountNameValid = debitAccountName.length > ACCOUNT_NAME_MIN_LENGTH;
    const debitAccountSortCodeValid =
      debitAccountSortCode.length >= SORT_CODE_MIN_LENGTH &&
      debitAccountSortCode.length <= SORT_CODE_MAX_LENGTH;
    setFormValid(debitAccountNumberValid && debitAccountNameValid && debitAccountSortCodeValid);
  }, [debitAccountNumber, debitAccountName, debitAccountSortCode]);

  const summaryContent = () => {
    if (!cart) return [{ name: "---", price: "---" }];

    if (cart?.sky_qs.length) {
      return [{ name: cart.plan_name + " plan with " + cart.sky_qs[0].name, price: cart.total }];
    }

    return cart?.plans.map((p: CartPlan) => {
      return { name: p.name, price: p.cost };
    });
  };

  return (
    <>
      <span className="back white-link" onClick={() => goBackClicked()}>
        <Chevron className="left-chevron" /> Back
      </span>
      <ProgressBar activeDots={4} numberOfDots={4} />
      <h1 className={JOURNEY_COLOURS[journey] + " m-0 name-text"}>
        PAYMENT
        <FontAwesomeIcon icon={faLock} color="gray" />
      </h1>
      {cart ? (
        <Card
          summary={true}
          summaryContent={summaryContent()}
          contentHeader={"TOTAL"}
          price={cart.total}
          duration={cart.duration_text}
          rightSideIcon={<FontAwesomeIcon icon={faCheck} />}
          smallPrint={cart.package_description}
          currencySymbol={cart.currency_symbol}
        />
      ) : (
        <Loader color={JOURNEY_COLOURS[journey]} />
      )}

      <RadioGroup
        handler={setPaymentSelected}
        type="paymentMethod"
        journey={journey}
        paymentSelected={paymentSelected}
        cart={cart}
      />
      {creditCardError ? <p className="error-text">{creditCardError}</p> : null}

      {iframeSrcHtml && showPaymentOptions ? (
        <iframe
          id="paymentIframe"
          style={{ width: "100%", height: "24rem" }}
          scrolling="no"
          src={"data:text/html," + encodeURIComponent(iframeSrcHtml)}></iframe>
      ) : (
        <Loader color={JOURNEY_COLOURS[journey]} />
      )}

      {loading && paymentSelected.creditCard && <Loader color={JOURNEY_COLOURS[journey]} />}

      {/* {creditCardError && paymentSelected.creditCard && (
        <p className="error-message">{creditCardError}</p>
      )} */}

      {paymentSelected.debitCard ? (
        <>
          <Input
            className="container-color"
            type="text"
            label="Account Name"
            placeHolder="Account Name"
            minLength={ACCOUNT_NAME_MIN_LENGTH}
            updateFunc={(e: any) => setDebitAccountName(e.target.value)}
          />

          <div className="columns align-direct-debit-fields">
            <Input
              className="container-color"
              type="text"
              label={showSEPA ? "IBAN" : "Account Number"}
              placeHolder={showSEPA ? "IBAN" : "Account Number"}
              minLength={ACCOUNT_NUMBER_MIN_LENGTH}
              maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
              updateFunc={(e: any) => setDebitAccountNumber(e.target.value)}
            />
            <span className="row-spacer thirty"></span>

            <Input
              className="container-color"
              type="text"
              label={showSEPA ? "BIC" : "Sort Code"}
              placeHolder={showSEPA ? "BIC" : "Sort Code"}
              minLength={SORT_CODE_MIN_LENGTH}
              maxLength={SORT_CODE_MAX_LENGTH}
              value={debitAccountSortCode}
              updateFunc={(e: any) => updateDebitAccountSortCode(e.target.value)}
            />
          </div>
          {directDebitError ? <p className="error-text">{directDebitError}</p> : null}
          <div className="center space-top">
            <Button
              className="wide"
              disabled={!formValid}
              text="Agree and Subscribe"
              size="medium"
              color={JOURNEY_COLOURS[journey]}
              onClick={() => submitDirectDebit()}
              rightComponent={loading ? <Loader color={JOURNEY_COLOURS[journey]} /> : null}
            />
          </div>
          <Terms />
        </>
      ) : (
        <Terms />
      )}
    </>
  );
};

const Terms = () => {
  return (
    <div className="terms">
      <p>
        By clicking the “[Agree and Subscribe]” button above, you confirm that you are over 18, have
        read and agree to our
        <span
          onClick={() => postMessageToParent({ navigate: true, internalUrl: `/terms-conditions` })}
          className="accent-link ml-3">
          Terms and Conditions
        </span>{" "}
        and you acknowledge our
        <span
          onClick={() => postMessageToParent({ navigate: true, internalUrl: `/privacy-policy` })}
          className="accent-link ml-3">
          Privacy Policy
        </span>
        , consent to your membership/day pass access beginning immediately, and acknowledge that you
        will not be able to withdraw from the contract and receive a refund once your membership/day
        pass has begun. Your day pass or first membership payment will be taken immediately. If you
        have subscribed to a monthly/annual membership: you will be charged your monthly or annual
        membership fee on a recurring basis until you cancel. You can cancel your monthly or annual
        membership at any time after the initial 12-month minimum term. If you cancel, your
        membership will continue until the end of your then-current membership period and you will
        not receive a refund.
      </p>
    </div>
  );
};

export default Payment;
