import React from "react";
import { DiscountPlan, Plan } from "../../shared/Models";
import { JourneyType, JOURNEY_COLOURS } from "../../shared/Theme";
import RadioButton, { RadioButtonProps } from "./RadioButton";
import { Cart } from "../../join/Payment";

export type RadioGroupProps = {
  type: payment;
  handler: any;
  journey: JourneyType;
  planSelected?: Plan | DiscountPlan;
  availablePlans?: Plan[];
  discountPlans?: DiscountPlan[];
  paymentSelected?: paymentSelectedObject;
  currencyCode?: "GBP" | "EUR";
  cart?: Cart;
};

export type paymentSelectedObject = {
  creditCard: boolean;
  debitCard: boolean;
};

export type payment = "paymentMethod" | "paymentDuration";

const RadioGroup: React.FC<RadioGroupProps> = ({
  type,
  handler,
  journey,
  planSelected,
  discountPlans = [],
  availablePlans = [],
  paymentSelected,
  currencyCode,
  cart
}) => {
  const CURRENCY_SYMBOLS = { GBP: "£", EUR: "€" };
  const passFreeDayCode =
    sessionStorage.getItem("freeDayPasscode") === "Free Day Pass" ? "true" : "false";
  const direct_debit_allow = sessionStorage.getItem("countryCode") || undefined;

  const [freeDayPassPackage, setFreeDayPassPackage] = React.useState<boolean>(
    !!passFreeDayCode ? passFreeDayCode === "true" : false
  );

  const handlePaymentSelect = (id: string) => {
    handler({ creditCard: id === "creditCard", debitCard: id === "debitCard" });
  };

  if (type === "paymentMethod" && paymentSelected) {
    return (
      <div className="radio-group-container row">
        <RadioButton
          id="creditCard"
          selectedClassName={paymentSelected.creditCard ? "selected" : "notSelected"}
          middleText="Debit / Credit Card"
          size="small"
          border="gray"
          color="brand"
          onClick={() => handlePaymentSelect("creditCard")}
        />
        {direct_debit_allow != "ROTW" && cart?.plan_name !== "Day Pass" && (
          <RadioButton
            id="debitCard"
            selectedClassName={paymentSelected.debitCard ? "selected" : "notSelected"}
            middleText="direct debit"
            size="small"
            border="gray"
            color="brand"
            onClick={() => handlePaymentSelect("debitCard")}
          />
        )}
      </div>
    );
  }

  const planSelectedClassName = (planName: string): RadioButtonProps["selectedClassName"] => {
    if (planSelected) {
      if (planSelected.name === planName) return "selected";
      return "notSelected";
    }
    return "";
  };

  const priceFor = (plan: any) => {
    const planCost = Number(plan.cost);
    if (planCost > 0) return `${CURRENCY_SYMBOLS[currencyCode as "GBP" | "EUR"]}${plan.cost}`;
    if (!planCost) return `${CURRENCY_SYMBOLS[currencyCode as "GBP" | "EUR"]}0.00`;
  };

  if (type === "paymentDuration" && (availablePlans || discountPlans)) {
    // const filterPlans = availablePlans.filter(
    //   (plan) => !discountPlans.some((x) => plan.id === x.plan_id)
    // );

    const plans_available = availablePlans;
    // discountPlans.length > 0 ? [...discountPlans, ...filterPlans] : [...availablePlans];
    return (
      <div className="radio-group-container">
        {plans_available
          .filter((x) => x.name != "Free Day Pass")
          .map((x) => (
            <RadioButton
              id={
                x?.subscription_length == "until midnight"
                  ? "day"
                  : x?.subscription_length == "per month"
                  ? "month"
                  : x?.subscription_length == "per year"
                  ? "year"
                  : ""
              }
              selectedClassName={planSelectedClassName(x.name)}
              title={x?.name.toLowerCase().includes("annually") ? "Annual" : x?.name}
              price={priceFor(x)}
              timeLength={x?.subscription_length}
              size="large"
              rightText={x.description}
              border={JOURNEY_COLOURS[journey]}
              color="brand"
              discount={x?.plan_type == ("discount" as any) ? true : false}
              onClick={() => {
                handler(x);
                setFreeDayPassPackage(x.name == "Free Day Pass" ? true : false);
              }}
              lozenge={{
                showLozenge: x.show_lozenge,
                text: x.lozenge_text,
                textColor: x.lozenge_text_colour,
                boxColor: x.lozenge_box_colour
              }}
            />
          ))}
      </div>
    );
  }
  return null;
};

export default RadioGroup;
